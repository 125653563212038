import React from 'react'

import ContentNav from '../../ContentNav'
import Content from '../../Content'

const Facts = ({ data, links }: any) => {
  return (
    <>
      <ContentNav links={links} active={data.active} />
      <Content>
        <h1>{data.title}</h1>

        <div
          dangerouslySetInnerHTML={{
            __html: data.body
          }}
        />
      </Content>
    </>
  )
}

export default Facts
