import React, { ReactElement } from 'react'
import AboutUsLayout from '../../../components/Layout/AboutUs'

import SEO from '../../../components/SEO/SEO'

import Facts from '../../../components/AboutUsPage/Facts'

const AboutUsPage = ({ pageContext: { fact, links } }: any): ReactElement => {
  return (
    <AboutUsLayout>
      <SEO title={`${fact.name} - ALISI | Ayala Land`} />
      <Facts data={fact} links={links} />
    </AboutUsLayout>
  )
}

export default AboutUsPage
